import { MenuCreateOptionInterface } from 'kernel/types/module'
class menu {
  private vm: Vue
  constructor() {
    this.vm = window.rootVm
  }

  get() : {[key: string]: any} {
    return {
      admin: [
        {
          create: (options: MenuCreateOptionInterface ) => options.hasRole(['ROLE_ADMIN', 'ROLE_VIDEO']),
          icon: 'fa fa-film',
          label: this.vm.$t('page.video'),
          route: {
            name: 'video',
          },
        },
      ],
    }
  }
}

export default () => (new menu()).get()
